
import './App.css';
import { HashRouter as Router, Routes, Route } from "react-router-dom"
import { Container } from 'react-bootstrap';
import Homepage from "./Components/Homepage"
import Oursoultions from './Components/Oursoultions';
// import Aboutus from './Components/Aboutus';
import Contactus from './Components/Contactus';
import Requestdemo from './Components/Requestdemo';
// import Banner from './Components/Banner';
import Logout from './Components/Logout';
// import Dashboardadmin from './Components/Dashboardadmin';
// import Article from './Components/Article';
// import Viewarticleadmin from './Components/Viewarticleadmin';
// import Categorymaster from './Components/Categorymaster';
// import Materialmaster from './Components/Materialmaster';
// import Returnpolicy from './Components/Returnpolicy';
// import Terms from './Components/Terms';
// import Privacypolicy from './Components/Privacypolicy';
// import Viewarticle1 from './Components/Viewarticle1';
// import Viewarticle2 from './Components/Viewarticle2';
// import Viewarticle3 from './Components/Viewarticle3';
// import Viewarticle4 from './Components/Viewarticle4';
// import Viewarticle5 from './Components/Viewarticle5';


function App() {
  return (
    <div className="">
      <Container fluid className="">
      <Router>
        <Routes>
          <Route exact path="/" element={<Homepage />} />
          <Route exact path="/Oursoultions/:selectsolution" element={<Oursoultions />} />
          {/* <Route exact path="/Aboutus" element={<Aboutus />} /> */}
          <Route exact path="/Contactus" element={<Contactus />} />
          <Route exact path="/Requestdemo" element={<Requestdemo />} />
          {/* <Route exact path="/Banner" element={<Banner />} /> */}
          <Route exact path="/Logout" element={<Logout />} />
          {/* <Route exact path="/Dashboardadmin" element={<Dashboardadmin />} />
          <Route exact path="/Article" element={<Article />} />
          <Route exact path="/Viewarticleadmin/:id" element={<Viewarticleadmin />} />
          <Route exact path="/Categorymaster" element={<Categorymaster />} />
          <Route exact path="/Materialmaster" element={<Materialmaster />} />
          <Route exact path="/Returnpolicy" element={<Returnpolicy />} />
          <Route exact path="/Terms" element={<Terms />} />
          <Route exact path="/Privacypolicy" element={<Privacypolicy />} />
          <Route exact path="/Viewarticle1" element={<Viewarticle1 />} />
          <Route exact path="/Viewarticle2" element={<Viewarticle2 />} />
          <Route exact path="/Viewarticle3" element={<Viewarticle3 />} />
          <Route exact path="/Viewarticle4" element={<Viewarticle4 />} />
          <Route exact path="/Viewarticle5" element={<Viewarticle5 />} /> */}

        </Routes>
      </Router>
      </Container>
    </div>
  );
}

export default App;
