import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { Row, Col, Navbar, Nav, Modal, Container, Form, Button, NavDropdown, Offcanvas, ModalBody } from "react-bootstrap"
import logo from "./Images/LNAIOS_Logo-withoutbg.png"
//import logo from "./Images/Ishaan_imgs/beamslogonew.png"
import { RiArrowDropDownLine } from "react-icons/ri"
import { FaUserAlt, FaSistrix, FaSearch, FaHome } from "react-icons/fa"
import Url from "./Url"
//import { Next } from "react-bootstrap/esm/PageItem"

const Navigation = () => {

    const url1 = Url()
    const url = url1["url"]
    const url2 = url1["url1"]

    const [showsearchmodal, setShowsearchmodal] = useState(false)
    const [requestdemomodal, setRequestdemomodal] = useState(false)

    const [showlogin, setShowlogin] = useState(false)

    const [email, setEmail] = useState("")
    const [mobileno, setMobileno] = useState("")
    const [name, setName] = useState("")
    const [password, setPassword] = useState("")
    const [isSent, setIsSent] = useState(false)
    // const [colorChange, setColorchange] = useState(false);
    const [mobilescr, setMobilescr] = useState(false)
    const [getdevwidth, setGetdevwidth] = useState("")

    const [newuser, setNewuser] = useState(false)

    useEffect(() => {
        setGetdevwidth(window.innerWidth + 'px')
        if (window.innerWidth <= 500) {
            setMobilescr(true)
        }
        //console.log(getdevwidth)
    }, [getdevwidth])

    // window.addEventListener('scroll', changeNavbarColor);

    /*---------------------Login --------------------- */

    const login = e => {
        e.preventDefault()
        const controller = "login"
        const api = url + controller
        //var api = "http://127.0.0.1:8000/api/login"
        fetch(api, {
            method: 'POST',
            body: JSON.stringify({ email, password }),
            headers: { 'Content-Type': 'application/json' }
        })
            .then((apidata) => {
                return apidata.json()
            })
            .then((actualdata) => {
                if (actualdata.token != "") {
                    localStorage.setItem("usertoken", actualdata.token)
                    localStorage.setItem("email", email)
                    setIsSent(true)
                }
                if (actualdata.message === "These credentials do not match our records.") {
                    alert("Invalid Email or password")
                    // toast.error('Invalid Email or password', { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })
                }
            })
    }

    if (isSent) {
        window.location = "#/Dashboardadmin"
    }

    /*---------------------Login end--------------------- */

    /*---------------------Register --------------------- */

    const register = e => {
        e.preventDefault()
        const controller = "login"
        const api = url + controller
        //var api = "http://127.0.0.1:8000/api/login"
        fetch(api, {
            method: 'POST',
            body: JSON.stringify({ email, password }),
            headers: { 'Content-Type': 'application/json' }
        })
            .then((apidata) => {
                return apidata.json()
            })
            .then((actualdata) => {
                if (actualdata.token != "") {
                    localStorage.setItem("usertoken", actualdata.token)
                    localStorage.setItem("email", email)
                    setIsSent(true)
                }
                if (actualdata.message === "These credentials do not match our records.") {
                    alert("Invalid Email or password")
                    // toast.error('Invalid Email or password', { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })
                }
            })
    }

    if (isSent) {
        window.location = "#/Dashboardadmin"
    }

    /*---------------------Register end --------------------- */

    return (

        <div className="">

            <Modal
                size=""
                show={requestdemomodal}
                onHide={() => setRequestdemomodal(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton></Modal.Header>
                <ModalBody>
                    <Row className="" style={{ padding: "20px" }}>

                        <Row className="homereqmsghead justify_content_center mt-0">
                            Request Demo
                        </Row>
                        <Row className="floating-label mt-3">
                            <input className="floating-input" type="text" placeholder=" "
                                required
                            />
                            <span className="highlight"></span>
                            <label>Your name *</label>
                        </Row>
                        <Row className="floating-label mt-0">
                            <input className="floating-input" type="email" placeholder=" "
                                required
                            />
                            <span className="highlight"></span>
                            <label>Your Email *</label>
                        </Row>
                        <Row className="floating-label mt-0">
                            <input className="floating-input" type="text" placeholder=" "
                                required
                            />
                            <span className="highlight"></span>
                            <label>Your Phone no *</label>
                        </Row>
                        <Row className="floating-label mt-0">
                            <input className="floating-input" type="text" placeholder=" "

                            />
                            <span className="highlight"></span>
                            <label>Your Company Name </label>
                        </Row>
                        <Row className="floating-label mt-0">
                            <input className="floating-input" type="text" placeholder=" "

                            />
                            <span className="highlight"></span>
                            <label>Your Location </label>
                        </Row>

                        <Row className="homesecondtop mt-0">
                            Product Interest
                        </Row>
                        <Row className="floating-label mt-0">
                            <select className="floating-input" type="text" placeholder=" ">
                                <option value="">Select Product</option>
                                <option value="Transportation Exchange Platform">Transportation Exchange Platform</option>
                                <option value="Transportation Intelligence Platform">Transportation Intelligence Platform</option>
                                <option value="Billing Platform ">Billing Platform </option>
                                <option value="Open Track & Trace Platform">Open Track & Trace Platform</option>
                            </select>
                        </Row>


                        <Row className="justify_content_center mt-1">
                            <button className="homknowmorebtn">Submit</button>
                        </Row>

                    </Row>
                </ModalBody>
            </Modal >

            <Modal
                size="lg"
                show={showsearchmodal}
                onHide={() => setShowsearchmodal(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton></Modal.Header>
                <Row>
                    <Col xs={1}></Col>
                    <Col>
                        <Row className=" mb-2" style={{ display: "block" }}>
                            {/* <Col xs={8} className="justify_content_left mb-5">
                                <input className="floating-input" type="text" placeholder="Search here" />
                            </Col>
                            <Col xs={2} lassName="justify_content_right" style={{alignItems: "center"}}>
                                <Row>
                                    <button className="navsearchmodalbtn">Search</button>
                                </Row>
                                </Col> */}
                            <Col className=" mb-5">
                                <span >
                                    <FaSearch className="homnavsrchicon" />
                                </span>
                                <span>
                                    <input type="text" className="navsearchmodalinput" placeholder="" />
                                </span>
                                <span className="mx-2">
                                    <button className="navsearchmodalbtn">Search</button>

                                </span>
                            </Col>
                            {/* <Col sm={2} lassName="justify_content_right" style={{ alignItems: "center" }}>
                                
                            </Col> */}
                        </Row>
                        {/* <Row className="justify_content_center mt-5">
                                
                            </Row> */}
                    </Col>
                    <Col xs={1}></Col>
                </Row>
            </Modal >

            <Modal
                size="lg"
                show={showlogin}
                onHide={() => setShowlogin(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton></Modal.Header>
                <Row>
                    <Col xs={1}></Col>
                    {
                        !newuser ?
                            <Col>
                                <form onSubmit={login}>
                                    <Row className="homloginrect mb-5" >
                                        <Col className="mt-0">

                                            <Row className="homereqmsghead mt-4">
                                                Login
                                            </Row>
                                            <Row className="floating-label mt-4">
                                                <input className="floating-input" type="email" placeholder=" "
                                                    required
                                                    onChange={e => setEmail(e.target.value)}
                                                />
                                                <span className="highlight"></span>
                                                <label>Email *</label>
                                            </Row>
                                            <Row className="floating-label mt-4">
                                                <input className="floating-input" type="password" placeholder=" "
                                                    required
                                                    onChange={e => setPassword(e.target.value)}
                                                />
                                                <span className="highlight"></span>
                                                <label>Password *</label>
                                            </Row>
                                            <Row className="justify_content_left mt-5">
                                                <Col>

                                                    <button className="homgreqmsgbtn">Submit</button>

                                                </Col>
                                                <Col className="newusertext" onClick={() => setNewuser(true)}>
                                                    New User?
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </form>
                            </Col>
                            :
                            <Col>
                                <form onSubmit={register}>
                                    <Row className="homloginrect  mb-5" >
                                        <Col className="mt-0">

                                            <Row className="homereqmsghead mt-4">
                                                Register
                                            </Row>
                                            <Row className="floating-label mt-4">
                                                <input className="floating-input" type="text" placeholder=" "
                                                    pattern="[1-9]{1}[0-9]{9}"
                                                    title="Please enter 10 digit mobile no...."
                                                    required
                                                    onChange={e => setMobileno(e.target.value)}
                                                />
                                                <span className="highlight"></span>
                                                <label>Mobile No *</label>
                                            </Row>
                                            <Row className="floating-label mt-4">
                                                <input className="floating-input" type="text" placeholder=" "
                                                    pattern="[a-zA-Z\s]+"
                                                    title="Please enter valid name. No special character or numerics values are allowed...."
                                                    required
                                                    onChange={e => setName(e.target.value)}
                                                />
                                                <span className="highlight"></span>
                                                <label>Name *</label>
                                            </Row>
                                            <Row className="floating-label mt-4">
                                                <input className="floating-input" type="password" placeholder=" "
                                                    required
                                                    onChange={e => setPassword(e.target.value)}
                                                />
                                                <span className="highlight"></span>
                                                <label>Password *</label>
                                            </Row>
                                            <Row className="justify_content_left mt-5">
                                                <Col>
                                                    <button className="homgreqmsgbtn">Submit</button>
                                                </Col>
                                                <Col className="newusertext" onClick={() => setNewuser(false)}>
                                                    Already registered?
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </form>
                            </Col>
                    }
                    <Col xs={1}></Col>
                </Row >
            </Modal >

            {
                !mobilescr ?
                    <Navbar bg="" variant="" expand="sm" fixed="top" className='navbg_white mb-5'>

                        <Link to="/">
                            <img src={logo} alt="beamslogo" className="beamslogonew" />
                        </Link>

                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="mr-auto">
                                {/* <Link to="" className="nav-link-black navigation_dropdown mt-0" >Hand weaving sarees{""}<RiArrowDropDownLine style={{ fontSize: "30px" }} />
                                <div className="dropdown-content">
                                    <Link to="/" className="nav-link-black dropdown_menu">Pure silk sarees</Link>
                                    <Link to="/" className="nav-link-black">Tussar silk</Link>
                                    <Link to="/" className="nav-link-black">Art silk</Link>
                                    <Link to="/" className="nav-link-black">Semi silk </Link>
                                </div>
                            </Link> */}

                                <Link to="/" className="nav-link-black1">Home</Link>
                                <Link to="" className="nav-link-red1 navigation_dropdown" style={{ marginTop: "12px" }}>Our Solutions{""}<RiArrowDropDownLine style={{ fontSize: "30px" }} />
                                    <div className="dropdown-content">
                                        <Link to={"/Oursoultions/" + "Transportation_Exchange_Platform"} className="nav-link-red1 dropdown_menu">Transportation Exchange Platform</Link>
                                        <Link to={"/Oursoultions/" + "Transportation_Intelligence_Platform"} className="nav-link-red1">Transportation Intelligence Platform</Link>
                                        <Link to={"/Oursoultions/" + "Billing_Platform"} className="nav-link-red1">Billing Platform</Link>
                                        <Link to={"/Oursoultions/" + "Open_Track_Trace_Platform"} className="nav-link-red1">Open Track & Trace Platform </Link>
                                    </div>
                                </Link>
                                {/* <Link to="/Oursoultions" className="nav-link-black1 ">Our Solutions</Link> */}
                                <Link to="/Contactus" className="nav-link-black1 ">Contact Us</Link>
                                <Link to="" className="">
                                    <button className="navreqdemobtn mt-0"
                                        onClick={() => setRequestdemomodal(true)}
                                    >
                                        Request Demo
                                    </button>
                                </Link>
                                {/* <Link to="/Oursoultions" className='nav-link-black mt-3'>Our Solutions</Link>
                            <Link to="/Contactus" className='nav-link-black mt-3'>Contact Us</Link>
                            <Link to="/" className='nav-link-black mt-3'>Request Demo</Link> */}
                            </Nav>

                        </Navbar.Collapse>

                    </Navbar>

                    :
                    <>
                        <Navbar expand="sm" className="bg-body-tertiary mb-3" style={{ background: "none" }}>
                            <Container fluid>
                                <Link to="/">
                                    <img src={logo} alt="beamslogo" className="beamslogonew" />
                                </Link>
                                <Navbar.Toggle aria-controls="nav-link-black" />
                                <Navbar.Offcanvas

                                >
                                    <Offcanvas.Header closeButton>
                                        <Offcanvas.Title >
                                            <Link to="/">
                                                <img src={logo} alt="beamslogo" className="beamslogonew" />
                                            </Link>
                                        </Offcanvas.Title>
                                    </Offcanvas.Header>
                                    <Offcanvas.Body>
                                        <Nav className="justify-content-end flex-grow-1 pe-3">
                                            <Link to="/" className="nav-link-black1">Home</Link>
                                            {/* <Link to="/Oursoultions" className="nav-link-black1 mt-2">Our Solutions</Link> */}
                                            <Link to="" className="nav-link-red1 navigation_dropdown mt-2" >Our Solutions{""}<RiArrowDropDownLine style={{ fontSize: "30px" }} />
                                                <div className="dropdown-content">
                                                    <Link to={"/Oursoultions/" + "Transportation_Exchange_Platform"} className="nav-link-red1 dropdown_menu">Transportation Exchange Platform</Link>
                                                    <Link to={"/Oursoultions/" + "Transportation_Intelligence_Platform"} className="nav-link-red1">Transportation Intelligence Platform</Link>
                                                    <Link to={"/Oursoultions/" + "Billing_Platform"} className="nav-link-red1">Billing Platform</Link>
                                                    <Link to={"/Oursoultions/" + "Open_Track_Trace_Platform"} className="nav-link-red1">Open Track & Trace Platform </Link>
                                                </div>
                                            </Link>
                                            <Link to="/Contactus" className="nav-link-black1 mt-2">Contact Us</Link>
                                            <Link to="" className="nav-link-black1 mt-2">
                                                <button className="navreqdemobtn mt-0"
                                                    onClick={() => setRequestdemomodal(true)}
                                                >
                                                    Request Demo
                                                </button>
                                            </Link>
                                        </Nav>
                                    </Offcanvas.Body>
                                </Navbar.Offcanvas>
                            </Container>
                        </Navbar>

                        {/* <Navbar bg="light" fixed="bottom" data-bs-theme="dark" style={{ background: "none" }}>
                        <Container>
                            <Nav className="">
                                <Row>
                                    <Col className="">
                                        <Row className="">
                                            <Link to="/" className="nav-link-black justify_content_center">
                                                <FaHome className="bottomnavuser_black" /></Link>
                                        </Row>
                                    </Col>

                                    <Col className="">
                                        <Row>
                                            <Link to="/" className="nav-link-black justify_content_center"><FaSearch className="bottomnavuser_black" /></Link>
                                        </Row>
                                    </Col>
                                    <Col className="">
                                        <Row>
                                            <Link to="/" className="nav-link-black justify_content_center"><FaUserAlt className="bottomnavuser_black" /></Link>
                                        </Row>
                                    </Col>
                                </Row>
                            </Nav>
                        </Container>
                    </Navbar> */}
                    </>
            }

        </div >
    )

}

export default Navigation