import React, { useEffect, useState } from "react";
import "./Styles.css"
import Footer from "./Footer";
import { Link } from "react-router-dom"
import Aos from "aos"
import "aos/dist/aos.css"
import Navigation from "./Navigation";
import { Row, Col, Carousel, Modal } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import { FaCheck, FaQuoteLeft, FaDotCircle } from "react-icons/fa"
// import Carouselimg1 from "./Images/Home corousel/colorful_threads1.jpg"
// import Carouselimg2 from "./Images/Home corousel/saree1.jpg"
// import Carouselimg3 from "./Images/Home corousel/textiles_sale1.jpg"
//import Truckimg from "./Images/Truckimg2.png"
import Transexchagevector from "./Images/transexchagevector.png"
import Transintelvector from "./Images/Transintelvector.png"
import Billingplatvector from "./Images/Billingplatvector.png"
import Opentrackvector from "./Images/Opentrackvector.png"
import Management1 from "./Images/Management1.png"
import Headoperation1 from "./Images/Headoperation1.png"
import Team1 from "./Images/Team1.png"
import Demovideo from "./Images/Demovideo.mp4"

// import Widget from "./Widget";
import Url from './Url';

const Homepage = () => {

    const url1 = Url()
    const url = url1["url"]
    const s3 = url["s3"]

    const [getbanner, setGetbanner] = useState("")

    const [knowmoretep, setKnowmoretep] = useState(false)
    const [showmanagement, setShowmanagement] = useState(false)
    const [showheadopt, setShowheadopt] = useState(false)
    const [showteam, setShowteam] = useState(false)

    const [videomodal, setVideomodal] = useState(true)

    const [mobilescr, setMobilescr] = useState(false)
    const [getdevwidth, setGetdevwidth] = useState("")

    useEffect(() => {

        setGetdevwidth(window.innerWidth + 'px')

        if (window.innerWidth <= 500) {
            setMobilescr(true)
        }
        //console.log(getdevwidth)

    }, [getdevwidth])

    // useEffect(() => {
    //     setGetbanner("WELCOME TO AARADHY SILK HAND CRAFT")
    // }, [])

    useEffect(() => {
        const api = url + "getActiveBannerOne"

        fetch(api, {
            method: 'GET',
        })
            .then((apidata) => {
                return apidata.json()
            })
            .then((actualdata) => {
                //console.log(actualdata)
                if (actualdata.message != "Not found") {
                    setGetbanner(actualdata.banner_name)
                }
                else {
                    setGetbanner("WELCOME TO AARADHY SILK HAND CRAFT")
                }
            })
    }, [])

    ///////////////Render top/////////////////////
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        Aos.init({ duration: 2000 })
    }, [])

    // const callBack = () => {
    //     // var elmnt = document.getElementById("Callback");
    //     // elmnt.scrollIntoView();
    //     // window.scrollTo({ behavior: 'smooth' });
    //     window.scrollTo(0, 800)
    // }

    return (
        <>
            {/* <Widget /> */}

            {/* <Modal
                size="lg"
                show={videomodal}
                onHide={() => setVideomodal(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <Row className=" justify_content_center">
                        <video
                            autoPlay
                            loop
                            controls
                            controlsList="nodownload"
                            className="projecttopvideo justify-content-center"
                        >
                            <source src={Demovideo} type="video/mp4" />
                        </video>
                    </Row>

                </Modal.Body>
            </Modal > */}

            <Modal
                size="lg"
                show={showmanagement}
                onHide={() => setShowmanagement(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <Row className="soltionsmoremodaltophead justify_content_center">
                        Management
                    </Row>
                    <Row className="mt-5">
                        <Col className="mx-4">
                            <Row className="soltionsmoremodalhead">
                                Dashboard / BI
                            </Row>
                            <Row className="oursolutiontext mt-3">
                                Make informed decisions with real-time insights and business intelligence at your finger-tips
                            </Row>
                        </Col>
                        <Col className="mx-4">
                            <Row className="soltionsmoremodalhead">
                                Branch / User Management
                            </Row>
                            <Row className="oursolutiontext mt-3">
                                Ability to manage multiple branches and users from a single interface, providing a complete overview
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col className="mx-4">
                            <Row className="soltionsmoremodalhead">
                                Customization / BPR (Business Process Re-engineering)
                            </Row>
                            <Row className="oursolutiontext mt-3">
                                A modular design enables you to pick and choose only that which suits your business needs
                            </Row>
                        </Col>
                        <Col className="mx-4">
                            <Row className="soltionsmoremodalhead">
                                Improving Visibility and Managing Risk
                            </Row>
                            <Row className="oursolutiontext mt-3">
                                Gain real-time end-to-end visibility and control at-risk components within the network resulting in shorter lead times and reduction in costs.
                            </Row>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal >

            <Modal
                size="lg"
                show={showheadopt}
                onHide={() => setShowheadopt(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <Row className="soltionsmoremodaltophead justify_content_center">
                        Head of Operations
                    </Row>
                    <Row className="mt-5">
                        <Col className="mx-4">
                            <Row className="soltionsmoremodalhead">
                                Freight Index
                            </Row>
                            <Row className="oursolutiontext mt-3">
                                Valuable insight on real-time lane wise rates to enable you to stay on top of market trends
                            </Row>
                        </Col>
                        <Col className="mx-4">
                            <Row className="soltionsmoremodalhead">
                                Information / Document Management
                            </Row>
                            <Row className="oursolutiontext mt-3">
                                Find all relevant information and documents with only a few clicks
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col className="mx-4">
                            <Row className="soltionsmoremodalhead">
                                Payment Portal for Carriers & Vendors
                            </Row>
                            <Row className="oursolutiontext mt-3">
                                Keep timely and accurate records of your vendor payments, with an option to connect all existing accounting systems under one roof
                            </Row>
                        </Col>
                        <Col className="mx-4">
                            <Row className="soltionsmoremodalhead">
                                Vendor Management
                            </Row>
                            <Row className="oursolutiontext mt-3">
                                Manage vendor activities, from on-boarding to payments, including assign ratings according to their performance.
                            </Row>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal >

            <Modal
                size="lg"
                show={showteam}
                onHide={() => setShowteam(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <Row className="soltionsmoremodaltophead justify_content_center">
                        Operations Team
                    </Row>
                    <Row className="mt-5">
                        <Col className="mx-4">
                            <Row className="soltionsmoremodalhead">
                                Load / Dispatch Management
                            </Row>
                            <Row className="oursolutiontext mt-3">
                                Manage day to day operational activities like carrier bidding, load posting etc, with ease.
                            </Row>
                        </Col>
                        <Col className="mx-4">
                            <Row className="soltionsmoremodalhead">
                                Segregated Transaction Views
                            </Row>
                            <Row className="oursolutiontext mt-3">
                                Monitoring each transaction on a task based level to allow greater control over processes in an attempt to increase efficiency.
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col className="mx-4">
                            <Row className="soltionsmoremodalhead">
                                Eliminate Duplicity
                            </Row>
                            <Row className="oursolutiontext mt-3">
                                With interconnected modules, avoid duplicate work by entering data points only once.
                            </Row>
                        </Col>
                        <Col className="mx-4">
                            <Row className="soltionsmoremodalhead">
                                Alert Based Monitoring
                            </Row>
                            <Row className="oursolutiontext mt-3">
                                Receive real-time transaction alerts to handle inconsistencies and improve compliance.
                            </Row>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal >


            {
                !mobilescr ?
                    <>
                        <Row className="mb-5">
                            <Navigation />
                        </Row>
                        <Row className="mt-5"></Row>
                        <Row className="mt-5"></Row>
                    </>
                    :
                    <Row className="">
                        <Navigation />
                    </Row>
            }
            {/* {
                !mobilescr ?
                    <>
                        <Row className="mt-5 "></Row>
                        <Row className="mt-5 "></Row>
                    </>
                    :
                    null
            } */}
            {/* {!mobilescr ?
                <Row className="mt-2 mb-0">
                    <Col xs className="justify_content_center homecounterbg">
                        <Col xs className="hometopadd justify_content_center " data-aos="fade-right">
                            <Col >
                                <Row className="hombannercardtext justify_content_center mt-2  mb-4">
                                    {getbanner}
                                </Row>
                            </Col>
                        </Col>
                    </Col>
                </Row>
                :
                <Row className="" style={{ marginTop: "-15px" }}>
                    <Col xs className="justify_content_center homecounterbg">
                        <Col xs className="hometopadd justify_content_center " data-aos="fade-right">
                            <Col >
                                <Row className="hombannercardtext justify_content_center mt-2  mb-4">
                                    {getbanner}
                                </Row>
                            </Col>
                        </Col>
                    </Col>
                </Row>
            } */}
            {/* <Row className="" style={{ marginTop: "-25px" }}>
                <Carousel fade className="">
                    <Carousel.Item interval='3000'>
                        <img
                            className="d-block w-100"
                            src={Carouselimg1}
                            alt="First slide"
                        />
                        <Carousel.Caption>
                            <Col xs></Col>
                            <Col xs className="mt-0">
                            </Col>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item interval='3000'>
                        <img
                            className="d-block w-100"
                            src={Carouselimg2}
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item interval='3000' >
                        <img
                            className="d-block w-100"
                            src={Carouselimg3}
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item interval='3000' >
                        <img
                            className="d-block w-100"
                            src={Carouselimg4}
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item interval='1000' >
                        <img
                            className="d-block w-100"
                            src={Carouselimg4}
                            alt="First slide"
                        />
                    </Carousel.Item>
                </Carousel>
            </Row> */}

            <Row className="homtruckimgbg justify_content_center " data-aos="fade-down">
                {/* <img src={Truckimg} className="homtruckimg" alt="" /> */}
                <Col xs={4} className="align_vertical_center mx-2">
                    <Row className=" justify_content_center homtopnametext mx-2" data-aos="fade-down">
                        <p>EFFICIENT</p> <p className="homtopnametext1">ORGANIZED</p> <p>ACCESSIBLE</p>
                    </Row>
                    {/* <Row className=" justify_content_center homtopnametext mx-2" data-aos="fade-down">
                        ORGANIZED
                    </Row>
                    <Row className=" justify_content_center homtopnametext mx-2" data-aos="fade-down">
                        ACCESSIBLE
                    </Row> */}
                </Col>
                <Col xs={2}></Col>
                <Col xs={4} className="align_vertical_center">

                    <Row className="Homevideobg justify_content_center ">
                        <Row className="mt-1">
                            <video
                                autoPlay
                                loop
                                controls
                                controlsList="nodownload"
                                className="projecttopvideo justify-content-center"
                            >
                                <source src={Demovideo} type="video/mp4" />
                            </video>
                        </Row>
                        <Row className="mt-2">
                            <Link to={"/Oursoultions/" + "Transportation_Exchange_Platform"} className="homevideoclickhere">
                                Click here to see the details of the company offerings
                            </Link>

                        </Row>

                    </Row>
                </Col>

            </Row>

            <Row className="bloglisthead1 justify_content_center mt-5 ">
                <b>Our vision is to <br />
                    Green the Road Ahead: Automate, Reduce, Thrive!</b>
            </Row>

            <Row className="mt-5">
                <Col xs={1}></Col>
                <Col sm className="mx-2 mt-2">
                    <Row className=" justify_content_center  homeourvisioncard1">
                        <Col>
                            <Row className="homvisionhead justify_content_center">Mission</Row>
                            <Row className="homvisiontext mt-3">Our mission is to engineer and deploy Logistics Next Generation, leveraging cutting-edge AI technology to automate and streamline business operations. We are dedicated to delivering cost-effective solutions that not only enhance operational efficiency but also minimize environmental impact through optimized transportation practices. Our aim is to empower businesses to thrive in a sustainable, digitally-driven future.
                            </Row>
                        </Col>
                    </Row>
                </Col >
                <Col sm className="mx-2 mt-2">
                    <Row className=" justify_content_center  homeourvisioncard2">
                        <Col>
                            <Row className="homvisionhead justify_content_center">What we offer</Row>
                            <Row className="homvisiontext mt-3">Experience a seamless, real-time cloud-based platform with LnAiOs, automating every facet of your transportation operations, from planning to execution and Proof of Delivery (PoD)/invoice submission. Our revolutionary system brings unmatched efficiency, accuracy, and cost-effectiveness.</Row>
                            <Row className="homvisiontext mt-0">LnAiOs goes beyond mere streamlining; it empowers you to control and drive logistics success. With intuitive industry-leading capabilities, reduce freight costs, optimize service levels, and automate tasks, liberating time and resources for core business activities.
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col sm className="mx-2 mt-2">
                    <Row className=" justify_content_center  homeourvisioncard3">
                        <Col>
                            <Row className="homvisionhead justify_content_center">Our Solutions</Row>
                            <Row className="homvisiontext mt-0">
                                <ul className="mt-3">
                                    <li>Transportation Exchange Platform</li>
                                    <li>Transportation Intelligence Platform</li>
                                    <li>Billing Platform</li>
                                    <li>Open Track & Trace Platform</li>
                                </ul>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col xs={1}></Col>
            </Row >

            <Row className="bloglisthead1 justify_content_center mt-5 ">
                <b>Our Solutions</b>
            </Row>

            <Row className="homsoltionshead justify_content_center mt-5 ">
                Transportation Exchange Platform
            </Row>

            <Row className="mt-4" data-aos="fade-down-right">
                <Col sm={1}></Col>
                <Col sm={3} className="justify_content_center">
                    <img src={Transexchagevector} className="solutionimg" alt="" />
                </Col>
                <Col sm className="mx-4">
                    <Row className="align_vertical_center mx-4">
                        <Row className="homsoltionstext ">
                            LnAiOs is a real time cloud-based Transportation Exchange Platform (TEP) designed to automate the end to end transportation activities in the supply chain. Combining ease of use with industry-leading capabilities, we enable you to reduce freight costs, optimize service levels, and automate processes to run your logistics operations more efficiently.
                        </Row>
                        <Row className="homsoltionstext">
                            The Platform delivers value via Price Discovery, Improvement in Operational Efficiency via Process Automation, providing real time end-to-end visibility and Business Intelligence among lots of other features.
                        </Row>
                        {
                            !knowmoretep ?
                                <Row className="mt-4">
                                    <button className="homknowmorebtn"
                                        onClick={() => setKnowmoretep(true)}
                                    >Know More</button>
                                </Row>
                                :
                                <Row className="mt-4">
                                    <button className="homknowmorebtn"
                                        onClick={() => setKnowmoretep(false)}
                                    >Hide</button>
                                </Row>
                        }
                    </Row>
                </Col>
                <Col sm={1}></Col>
            </Row>
            {
                knowmoretep ?
                    <Row className="mt-5">
                        <Col sm={1}></Col>
                        <Col sm className="oursolutioncard mx-4 mt-5">
                            <Row className="oursolutioninnercard">
                                <Row className="">
                                    <img src={Management1} className="oursolutionimg" alt="" />
                                </Row>
                                <Row className="homsoltionstext">
                                    Management
                                </Row>
                                <Row className="oursolutiontext mt-3">
                                    <ul>
                                        <li>Enhance service levels and professionalism</li>
                                        <li>Track and improve your profitability</li>
                                        <li>Spend more time with clients and prospects</li>
                                    </ul>
                                </Row>
                            </Row>

                            <Row className="justify_content_right">
                                <button className="hommorebtn"
                                    onClick={() => setShowmanagement(true)}
                                >More</button>
                            </Row>
                        </Col>
                        <Col sm className="oursolutioncard mx-4 mt-5">
                            <Row className="oursolutioninnercard">
                                <Row className="">
                                    <img src={Headoperation1} className="oursolutionimg" alt="" />
                                </Row>
                                <Row className="homsoltionstext">
                                    Head of Operations
                                </Row>
                                <Row className="oursolutiontext mt-3">
                                    <ul>
                                        <li>Cut down administration time</li>
                                        <li>Track and improve your KPIs</li>
                                        <li>Avoid leakages and dependency with a centralized system</li>
                                        <li>Monitor and secure your revenues</li>
                                    </ul>
                                </Row>
                            </Row>
                            <Row className="justify_content_right">
                                <button className="hommorebtn"
                                    onClick={() => setShowheadopt(true)}
                                >More</button>
                            </Row>
                        </Col>
                        <Col sm className="oursolutioncard mx-4 mt-5">
                            <Row className="oursolutioninnercard">
                                <Row className="">
                                    <img src={Team1} className="oursolutionimg" alt="" />
                                </Row>
                                <Row className="homsoltionstext">
                                    Operations Team
                                </Row>
                                <Row className="oursolutiontext mt-3">
                                    <ul>
                                        <li>Instruct vendors and drivers with status updates</li>
                                        <li>Prepare and process dispatches</li>
                                        <li>Manage and approve proof of delivery</li>
                                        <li>Save time and focus more on value-adding activities</li>
                                    </ul>
                                </Row>
                            </Row>
                            <Row className="justify_content_right">
                                <button className="hommorebtn"
                                    onClick={() => setShowteam(true)}
                                >More</button>
                            </Row>
                        </Col>
                        <Col sm={1}></Col>
                    </Row>
                    :
                    null
            }

            <Row className="homsoltionshead justify_content_center mt-5 ">
                Transportation Intelligence Platform
            </Row>

            <Row className="mt-4" data-aos="fade-down-left">
                <Col sm={1}></Col>
                <Col sm className="mx-4">
                    <Row className="align_vertical_center mx-4">
                        <Row className="homsoltionstext ">
                            An easy, fast and comprehensive platform which is specially designed to fulfill the current needs of the market with enhanced features:
                        </Row>
                        <Row className="homsoltionstext">
                            <ul>
                                <li>Actionable Dashboard for real-time decision making</li>
                                <li>Branch and User wise analytics</li>
                                <li>Multiple logins</li>
                                <li>Keep track of actionable items</li>
                                <li>Additional Closure Workflows</li>
                                <li>Ability to Scale; Manage additional volume with existing resources</li>
                                <li>Generate a bill / invoice in less than a minute</li>
                            </ul>
                        </Row>
                    </Row>
                </Col>
                <Col sm={3} className="justify_content_center">
                    <img src={Transintelvector} className="solutionimg" alt="" />
                </Col>
                <Col sm={1}></Col>
            </Row>

            <Row className="homsoltionshead justify_content_center mt-5 ">
                Billing Platform
            </Row>

            <Row className="mt-4" data-aos="fade-down-right">
                <Col sm={1}></Col>
                <Col sm={3} className="justify_content_center">
                    <img src={Billingplatvector} className="solutionimg" alt="" />
                </Col>
                <Col sm className="">
                    <Row className="align_vertical_center mx-4">
                        <Row className="homsoltionstext ">
                            A Modular platform for Corporates, SMEs, Transporters, Commission Agents, Truck Owners, Brokers who are looking to streamline their day to day operations.
                        </Row>
                        <Row className="homsoltionstext">
                            <ul>
                                <li>Load and Route Planning / Optimisation</li>
                                <li>Consignment Notes Management</li>
                                <li>Fleet Management</li>
                                <li>Trip Management</li>
                                <li>Last Mile Distribution</li>
                                <li>Financial Management</li>
                                <li>Analytics</li>
                                <li>Generate a bill / invoice in less than a minute</li>
                                <li>Tracking via GPS, Mobile Apps and Location Based Tracking</li>
                            </ul>
                        </Row>
                    </Row>
                </Col>
                <Col sm={1}></Col>
            </Row>

            <Row className="homsoltionshead justify_content_center mt-5 ">
                Open Track & Trace Platform
            </Row>

            <Row className="mt-4" data-aos="fade-down-left">
                <Col sm={1}></Col>
                <Col sm className="">
                    <Row className="align_vertical_center mx-4">
                        <Row className="homsoltionstext ">
                            Manage tracking for all vehicles with real-time movement, ability to trigger location based alerts and historical playback. We can also integrate with your existing service provider
                        </Row>
                        <Row className="homsoltionstext">
                            <ul>
                                <li>Integration with existing GPS based vehicles</li>
                                <li>Monitor all vehicles from within a single page</li>
                                <li>Information regarding Running Speed and Last Updated Location among other data points</li>
                                <li>Tracking via GPS, Mobile Apps</li>
                            </ul>
                        </Row>
                    </Row>
                </Col>
                <Col sm={3} className="justify_content_center">
                    <img src={Opentrackvector} className="solutionimg" alt="" />
                </Col>
                <Col sm={1}></Col>
            </Row>

            {/* 
             <Row className="homsoltionshead justify_content_center mt-5 ">
                Transportation Exchange Platform
            </Row>

           <Row className="mt-4" data-aos="fade-down-right">
                <Col sm={1}></Col>
                <Col sm={3} className="justify_content_center">
                    <img src={Transexchagevector} className="solutionimg" alt="" />
                </Col>
                <Col sm className="mx-4">
                    <Row className="align_vertical_center mx-4">
                        <Row className="homsoltionstext ">
                            LnAiOs is a real time cloud-based Transportation Exchange Platform (TEP) designed to automate the end to end transportation activities in the supply chain. Combining ease of use with industry-leading capabilities, we enable you to reduce freight costs, optimize service levels, and automate processes to run your logistics operations more efficiently.
                        </Row>
                        <Row className="homsoltionstext">
                            The Platform delivers value via Price Discovery, Improvement in Operational Efficiency via Process Automation, providing real time end-to-end visibility and Business Intelligence among lots of other features.
                        </Row>
                    </Row>
                </Col>
                <Col sm={1}></Col>
            </Row>

            <Row className="homsoltionshead justify_content_center mt-5 ">
                Transportation Intelligence Platform
            </Row>

            <Row className="mt-4" data-aos="fade-down-left">
                <Col sm={1}></Col>
                <Col sm className="mx-4">
                    <Row className="align_vertical_center mx-4">
                        <Row className="homsoltionstext ">
                            An easy, fast and comprehensive platform which is specially designed to fulfill the current needs of the market with enhanced features:
                        </Row>
                        <Row className="homsoltionstext">
                            <ul>
                                <li>Actionable Dashboard for real-time decision making</li>
                                <li>Branch and User wise analytics</li>
                                <li>Multiple logins</li>
                                <li>Keep track of actionable items</li>
                                <li>Additional Closure Workflows</li>
                                <li>Ability to Scale; Manage additional volume with existing resources</li>
                                <li>Generate a bill / invoice in less than a minute</li>
                            </ul>
                        </Row>
                    </Row>
                </Col>
                <Col sm={3} className="justify_content_center">
                    <img src={Transintelvector} className="solutionimg" alt="" />
                </Col>
                <Col sm={1}></Col>
            </Row>

            <Row className="homsoltionshead justify_content_center mt-5 ">
                Billing Platform
            </Row>

            <Row className="mt-4" data-aos="fade-down-right">
                <Col sm={1}></Col>
                <Col sm={3} className="justify_content_center">
                    <img src={Billingplatvector} className="solutionimg" alt="" />
                </Col>
                <Col sm className="">
                    <Row className="align_vertical_center mx-4">
                        <Row className="homsoltionstext ">
                            A Modular platform for Corporates, SMEs, Transporters, Commission Agents, Truck Owners, Brokers who are looking to streamline their day to day operations.
                        </Row>
                        <Row className="homsoltionstext">
                            <ul>
                                <li>Load and Route Planning / Optimisation</li>
                                <li>Consignment Notes Management</li>
                                <li>Fleet Management</li>
                                <li>Trip Management</li>
                                <li>Last Mile Distribution</li>
                                <li>Financial Management</li>
                                <li>Analytics</li>
                                <li>Generate a bill / invoice in less than a minute</li>
                                <li>Tracking via GPS, Mobile Apps and Location Based Tracking</li>
                            </ul>
                        </Row>
                    </Row>
                </Col>
                <Col sm={1}></Col>
            </Row>

            <Row className="homsoltionshead justify_content_center mt-5 ">
                Open Track & Trace Platform
            </Row>

            <Row className="mt-4" data-aos="fade-down-left">
                <Col sm={1}></Col>
                <Col sm className="">
                    <Row className="align_vertical_center mx-4">
                        <Row className="homsoltionstext ">
                            Manage tracking for all vehicles with real-time movement, ability to trigger location based alerts and historical playback. We can also integrate with your existing service provider
                        </Row>
                        <Row className="homsoltionstext">
                            <ul>
                                <li>Integration with existing GPS based vehicles</li>
                                <li>Monitor all vehicles from within a single page</li>
                                <li>Information regarding Running Speed and Last Updated Location among other data points</li>
                                <li>Tracking via GPS, Mobile Apps</li>
                            </ul>
                        </Row>
                    </Row>
                </Col>
                <Col sm={3} className="justify_content_center">
                    <img src={Opentrackvector} className="solutionimg" alt="" />
                </Col>
                <Col sm={1}></Col>
            </Row> */}

            <Row className="mt-5">
                <Footer />
            </Row>
        </>
    )
}

export default Homepage