import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Footer from "./Footer"
import Navigation from "./Navigation"
import { Row, Col, Form } from "react-bootstrap"
import { ImOffice } from "react-icons/im"
import { HiOutlineMail } from "react-icons/hi"
import { FiPhoneCall } from "react-icons/fi"
import CEO from "./Images/CEO.jpg"
import CMO from "./Images/CMO.jpg"

const Requestdemo = () => {

    const [mobilescr, setMobilescr] = useState(false)
    const [getdevwidth, setGetdevwidth] = useState("")

    useEffect(() => {

        setGetdevwidth(window.innerWidth + 'px')

        if (window.innerWidth <= 500) {
            setMobilescr(true)
        }
        //console.log(getdevwidth)

    }, [getdevwidth])


    ///////////////Render top/////////////////////
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (

        <div className="">

            {
                !mobilescr ?
                    <>
                        <Row className="mb-5">
                            <Navigation />
                        </Row>
                        <Row className="mt-5"></Row>
                        <Row className="mt-5"></Row>
                    </>
                    :
                    <Row className="">
                        <Navigation />
                    </Row>
            }

            

            <Row className=" justify_content_center mt-5 mb-5 " >
                <Col sm={1}></Col>
                <Col className="" >
                    <Row className="homreqmsgrect" data-aos="fade-up">
                        <Col className="mt-0">
                            <Row className="homesecondtop mt-5">
                                SEND A MESSAGE
                            </Row>
                            <Row className="homereqmsghead mt-4">
                                Request a call back
                            </Row>
                            <Row className="floating-label mt-5">
                                <input className="floating-input" type="text" placeholder=" "
                                    required
                                />
                                <span className="highlight"></span>
                                <label>Your name *</label>
                            </Row>
                            <Row className="floating-label mt-4">
                                <input className="floating-input" type="email" placeholder=" "
                                    required
                                />
                                <span className="highlight"></span>
                                <label>Email *</label>
                            </Row>
                            <Row className="floating-label mt-4">
                                <input className="floating-input" type="text" placeholder=" "
                                    required
                                />
                                <span className="highlight"></span>
                                <label>Mobile no *</label>
                            </Row>
                            <Row className="floating-label mt-4">
                                <textarea className="floating-input" type="text" placeholder=" " style={{ height: '120px' }}
                                    required
                                />
                                <span className="highlight"></span>
                                <label>Your message *</label>
                            </Row>

                            <Row className="justify_content_left mt-5">
                                <button className="homgreqmsgbtn">Submit</button>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col className="mt-0 mx-5" data-aos="fade-left">
                    <Row className="homesecondsecwelcom mt-5">
                        CONTACT US
                    </Row>
                    <Row className="mt-5">
                        <Col xs={1}></Col>
                        <Col xs>
                            <Row className="justify_content_center">
                                <img src={CEO} alt="" className="teamimg" />
                            </Row>
                            <Row className="Teammebname justify_content_center mt-4">Pankaj Kotwani</Row>
                            <Row className="Teammebdesign justify_content_center mt-1">Founder and CEO</Row>
                        </Col>
                        <Col xs>
                            <Row className="justify_content_center">
                                <img src={CMO} alt="" className="teamimg" />
                            </Row>
                            <Row className="Teammebname justify_content_center mt-4">Aryan Kotwani</Row>
                            <Row className="Teammebdesign justify_content_center mt-1">
                                Co-Founder and CMO
                            </Row>
                            <Row className="Teammebdesign justify_content_center mt-1">Chief Marketing Officer</Row>
                        </Col>
                        <Col xs={1}></Col>
                    </Row>


                    <Row className="homesecondsechead mt-4">
                        We’d Love to Hear From You.
                    </Row>
                    <Row className="mt-4">
                        <Col xs={3} className="homesecondsecborder"></Col>
                    </Row>
                    {/* <Row className="homesecondsecdesc mt-4 mx-2">
                        We deeply care for our clients, firmly believe in our people and are Passionate about our quality of services. We are equipped to meet the changing demands of the corporate and non-corporate entities with our trained personnel, capable of rendering wide spectrum of services.
                    </Row> */}
                    <Row className="mt-4">
                        <Col sm className="">
                            <Row>
                                <Col xs={3} className='contactusicon'>
                                    <FiPhoneCall />
                                </Col>
                                <Col>
                                    <Row className="contactusheads  mt-3">
                                        Call Us
                                    </Row>
                                    <Row className="contactusdesc  mt-0">
                                        +1 440 812 9027
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm>
                            <Row>
                                <Col xs={3} className='contactusicon'>
                                    <HiOutlineMail />
                                </Col>
                                <Col>
                                    <Row className="contactusheads  mt-3">
                                        Email Address
                                    </Row>
                                    <Row className="contactusdesc  mt-0">
                                        pankaj@logisticsaios.app
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col sm>
                            <Row>
                                <Col xs={3} className='contactusicon'>
                                    <ImOffice />
                                </Col>
                                <Col>
                                    <Row className="contactusheads  mt-2">
                                        Visit
                                    </Row>
                                    <Row className="contactusdesc  mt-0">
                                        Pankaj Kotwani<br />32200 Miles Rd<br />Solon, OH<br />
                                        44139, USA
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm></Col>
                    </Row>
                </Col>

            </Row>

            <Row className="footermargin  ">
                <Footer />
            </Row>

        </div>
    )
}

export default Requestdemo