import React from "react"
import { Row, Col } from "react-bootstrap"
// import footerlogo from "./Images/footer-logo.png"
import footerlogo from "./Images/LNAIOS_Logo-withoutbg.png"
import { TiSocialFacebook, TiSocialTwitter } from "react-icons/ti"
import { AiOutlineGoogle } from "react-icons/ai"
import { Link } from "react-router-dom"


const Footer = () => {

    return (
        <>

            <Row className="footerbg">
                <Row className="mb-5">
                    <Col sm={1}></Col>

                    <Col sm={4} className="ml-0 mx-5">
                        <Row className="mt-5 ">
                            <img src={footerlogo} alt="" className="footerlogo" />
                        </Row>
                        <Row className="footerlefttxt mt-3 mr-5">
                        Welcome to LnAiOs, where we're redefining the logistics landscape with our cutting-edge NextGen Operating System.
                        </Row>
                        <Row className="footerlefttxt mt-3 mr-5">
                        Our mission is simple: to empower businesses like yours with the tools and technology needed to thrive in today's fast-paced world.
                        </Row>
                        {/* <Row className="mt-5 ml-0">
                            <span className="footersocialiconbg ml-0 mr-0">
                                <TiSocialFacebook />
                            </span>
                            <span className="footersocialiconbg ml-2 mr-0">
                                <AiOutlineGoogle />
                            </span>
                            <span className="footersocialiconbg ml-2">
                                <TiSocialTwitter />
                            </span>
                        </Row> */}
                    </Col>
                    <Col sm className=" mx-5 mt-0">
                        <Row className="footercenterheadingtxt mt-5 ">
                            Quick Links
                        </Row>
                        <Link to="/">
                            <Row className="footercentercentertxt mt-4 ">
                                Home
                            </Row>
                        </Link>
                        <Link to={"/Oursoultions/" + "Transportation_Exchange_Platform"}>
                            <Row className="footercentercentertxt mt-3 ">
                                Our Solutions
                            </Row>
                        </Link>
                        <Link to="/Contactus">
                            <Row className="footercentercentertxt mt-3 ">
                                Contact us
                            </Row>
                        </Link>
                        {/* <Link to="/">
                            <Row className="footercentercentertxt mt-3 ">
                                Request Demo
                            </Row>
                        </Link> */}
                        
                        
                        {/* <Link to="Contactus/">
                            <Row className="footercentercentertxt mt-3 ">
                                Contact us
                            </Row>
                        </Link> */}
                        {/* <Link to="/uc">
                            <Row className="footercentercentertxt mt-3 ">
                                Blog
                            </Row>
                        </Link> */}
                        {/* <Link to="/Team">
                            <Row className="footercentercentertxt mt-3 ">
                                Team
                            </Row>
                        </Link> */}
                        {/* <Link to="/">
                            <Row className="footercentercentertxt mt-3 ">
                                Services
                            </Row>
                        </Link> */}
                        {/* <Link to="/">
                            <Row className="footercentercentertxt mt-3 ">
                                Disclaimer
                            </Row>
                        </Link> */}
                        {/* <Link to="/Contactus">
                            <Row className="footercentercentertxt mt-3 ">
                                Contact
                            </Row>
                        </Link> */}
                    </Col>
                    {/* <Col sm className="mx-5 mt-0">
                        <Row className="footercenterheadingtxt mt-5 ">
                            Useful Links
                        </Row>
                        <a href="https://ssipmt.com/" target="_blank">
                            <Row className="footercentercentertxt mt-4 ">
                                SSIPMT
                            </Row>
                        </a>
                        <a href="https://idealnet.aicte-india.org/" target="_blank">
                            <Row className="footercentercentertxt mt-4 ">
                                AICTE IDEA Lab
                            </Row>
                        </a>
                        <a href="https://www.aicte-india.org/" target="_blank">
                            <Row className="footercentercentertxt mt-4 ">
                                AICTE
                            </Row>
                        </a>
                    </Col> */}
                    <Col sm className="mx-5 mt-0">
                        <Row className="footercenterheadingtxt1 mt-5 ">
                            Contact Info
                        </Row>
                        <Row className="footercentercentertxt1 mt-4 ">
                        pankaj@logisticsaios.app<br />+1 440 812 9027<br /> 32200 Miles Rd<br />Solon, OH<br />
                        44139, USA
                        </Row>
                        {/* <Row className="footercentercentertxt1new mt-3 "
                            onClick={() => { document.location.href = "tel:+919xxxxxxxxx" }}
                        >
                            +91 8839083394
                        </Row> */}
                        <Row className="footercentercentertxt1 mt-3 ">
                            {/* info@ssipmt.com */}
                        </Row>
                    </Col>
                    <Col sm={1}></Col>
                </Row>



                {/* <Row className="footerhzline mt-4">

                    <Col sm={1}></Col>
                    <Col className="ml-0">
                        <Row className="footerlefttxt">
                            <Col>
                                © Copyright 2023 Aaradhy silk hand craft
                            </Col>
                        </Row>
                    </Col>
                    <Col sm={4} className="mx-0">
                        <span className="footercentercentertxt mx-3">
                            Terms of Service
                        </span>
                        <span className="footprivacymarginleft"></span>
                        <span className="footercentercentertxt  mx-3">
                            Privacy Policy
                        </span>
                    </Col>
                    <Col sm={1}></Col>

                </Row> */}

            </Row>

        </>
    )
}

export default Footer